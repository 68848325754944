@use "./config/" as *;
@forward './main.scss';
@forward './swiper/swiper-bundle';
@forward './events/eventlisting';
@forward "./module/education-hub";
.waf-eventlisting {
    &.waf-component {
        @extend %m-0;
        @extend %p-0;
    }
    .layout-wrapper {
        max-width: unset;
    }
    .waf-head {
        margin-inline: var(--space-2-neg);
        padding-top: calc(var(--secondary-header-height) + 7rem);
        @extend %relative;
        .title {
            @extend %d-block;
            @extend %neutral-0;
            @extend %title-30;
            @extend %mb-15;
            @include default-wrapper();
        }
        &::before {
            content: "";
            contain: paint;
            border-radius: 0 0 var(--border-radius-m) var(--border-radius-m);
            height: 40.5rem;
            @extend %zindex-pattern;
            @extend %position-t-l;
            @extend %w-100;
            @extend %h-100;
            @include background(var(--neutral-100), "cssimages/pattern/development-bg-m.png", top center / cover no-repeat);
        }
    }
    .head-wrap {
        > .sub-title {
            @extend %d-block;
        }
    }
    .body-filter-content{
        margin-top: 0;
    }
    .waf-body {
        @extend %mt-4-neg;
        @include large-wrapper();
    }
}
.waf-development-education {
    padding-block: var(--space-10);
    .waf-head {
        padding: var(--space-6);
    }
}
@include mq(col-tablet) {
    .waf-eventlisting {
        .waf-head {
            padding-top: calc(var(--secondary-header-height) + 11.3rem);
            &::before {
                border-radius: 0 0 var(--border-radius-xl) var(--border-radius-xl);
                height: 45.6rem;
                @include background(var(--neutral-100), "cssimages/pattern/development-bg-d.png", bottom center / cover no-repeat);
            }
            .title {
                font-size: 5.6rem;
            }
            .head-wrap {
                padding: var(--space-10);
            }
            .head-tab-list {
                margin-inline: var(--space-10-neg);
                padding-inline: var(--space-10);
            }
        }
        .waf-body{
            margin-top: var(--space-10-neg);
        }
    }
    .waf-development-education {
        .waf-head {
            padding: var(--space-8);
            &::before {
                top: -3rem;
                right: 1.6rem;
            }
        }
    }
}
@include mq(col-lg) {
    .waf-development-education {
        .waf-head {
            padding: var(--space-14);
        }
    }
}